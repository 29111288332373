<template>
    <div>
        <LoadingComponent 
            v-show="products.length === 0" 
            />
        <FicheProduct
            class="products"
            v-for="product in products" 
            :key="product['@id']"
            :product="product"
            />
    </div>
</template>
<script>

import FicheProduct from './fiche/FicheProduct.vue';
import LoadingComponent from './Loading.vue';
import { mapActions, mapState } from 'vuex';

export default {
    Name:'Products',
    components: {
        FicheProduct,
        LoadingComponent,

    },
    computed:{
        ...mapState(['products']),
        ...mapActions(['fetchProducts'])
    },
    created(){
        this.$store.dispatch('fetchProducts')
      
    }
}
</script>