<template>
  <div class="products">
    <h2>Produits</h2>
    <ProductsComponent class="exposition" style="justify-content: center"/>
  </div>
</template>
<script>
import ProductsComponent from '@/components/Products.vue'

export default {
  name: 'Exposition',
  components:{
    ProductsComponent,
  }
}
</script>