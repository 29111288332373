<template>
    <div>
        
        <div 
        class="card" style="width: 18rem"
        v-if="product.status"
        >
        <div style="height:20rem">
            <img class="card-img-top" :src="product.imagePath" alt="Card image cap" style="border-radius: 20px; max-width: 100%; max-height: 100%; width: auto; height: auto">
        </div>
            <div class="card-body">
                <h5 class="card-title">{{product.name}}</h5>
                <p class="card-text">{{ product.price }} €</p>
                <input type="number" min="1" style="width:20%" v-model="product.quantity" placeholder="0" value="0">
                <button type="submit" @click="prodInCart(product.id, product.name, product.price, product.quantity)" class="btn btn-outline-success btn-sm">Ajouter <b-icon-bag-plus></b-icon-bag-plus></button>
            </div>
        </div>
    </div>
</template>
<script>
import { BIconBagPlus } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';

export default {
    Name: "FicheProduct",
    components:{
        BIconBagPlus
    },
    props:{
        product:{
            type: Object,
            required: true
        }
    },
    data(){
        return{
            cardImage:"",
            totalPrice: 0
        }
    },
    computed:{
        ...mapState(['urlImage']),
        ...mapActions(['fetchUrlImage']),
        
       
    },
   created(){
      this.$store.dispatch('fetchUrlImage', this.product.ean)
   },
    methods: {
        prodInCart(id, name, price, quantity){
            if(quantity)
            {
                let prod = { id, name, price, quantity };
                this.$store.dispatch('insertInCart', prod)
            } 
        }
    },
}
</script>